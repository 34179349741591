<template>
  <v-container fluid>
    <base-material-card
      class="px-5 py-3" color="primary">
      <template v-slot:heading>
        <v-row class="ma-auto">
          <v-col md="8" sm="8">
            <v-icon left color="white">mdi-account-details</v-icon>
            {{$t('tables.all-managers')}}
            <v-badge offset-x="-5" offset-y="-5" color="blue"
                     :content="managers.length>0?managers.length:'0'"></v-badge>
          </v-col>
          <v-col md="4" sm="4">
            <CreateObjectButton :object="object"></CreateObjectButton>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </template>

      <v-card-title>
        <v-row class="ma-auto">
          <v-col cols="12" md="4" sm="12">
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" single-line hide-details>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        single-expand
        item-key="managerId"
        :loading="loading"
        multi-sort
        :headers="headers"
        :items="managers"
        :search="search"
        show-expand
        :expanded.sync="expanded"
        :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]
              }">
        <template v-slot:item.surname="{ item }">
          <router-link :to="{ path: '/manager/' + item.managerId}" class="no-underline-link">
            {{item.surname}}
          </router-link>
        </template>
        <template v-slot:item.agency.agencyName="{ item }">
          <router-link :to="{ path: '/agency/' + item.agency.agencyId}" class="no-underline-link">
            {{item.agency.agencyName}}
          </router-link>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length/2">
            <v-simple-table dense>
              <tr>
                <td>
                  {{$t('table-expand.created-date')}}:
                </td>
                <td>
                  <h5>{{ parseTime(item.createdTime) }}</h5>
                </td>
                <td>
                  {{$t('table-expand.modified-date')}}:
                </td>
                <td>
                  <h5>{{ parseTime(item.modifiedTime) }}</h5>
                </td>
              </tr>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import CreateObjectButton from "../../components/buttons/CreateObjectButton";
  import {mapState} from "pinia";
  import {useManagers} from "../../stores/managersStore";

  let moment = require('moment');

  export default {
    name: "Managers",
    components: {CreateObjectButton},
    data() {
      return {
        expanded: [],
        object: 'Manager',
        search: '',
        headers: [
          {text: this.$t('tables.full-name'), value: 'surname', class: "primary--text"},
          {text: this.$t('tables.agency'), value: 'agency.agencyName', class: "primary--text"},
          {text: this.$t('tables.email'), value: 'email', class: "primary--text"},
          {text: this.$t('tables.mobilePhone'), value: 'mobilePhone', class: "primary--text"},
          {text: this.$t('tables.position'), value: 'position', class: "primary--text"},
        ],
      }
    },
    computed: {
      ...mapState(useManagers, ['managers', 'loading'])
    },
    methods: {
      updateNeededCheck() {
        if (!this.managers.length) {
          const managersStore = useManagers()
          managersStore.populateManagers()
        }
      },
      parseTime(time) {
        moment.updateLocale(moment.locale(), {invalidDate: "Empty date"})
        return moment(time).format("DD.MM.YYYY HH:mm");
      },
    },
    created() {
      this.updateNeededCheck();
    },
  }
</script>

<style scoped>
  .no-underline-link {
    text-decoration: none;
  }
</style>
